// React
import React, { useEffect, useRef, useState } from "react";
// Components
import { Loader } from "./Loader";
import { Sphere360 } from "../sphere360/Sphere360";
import { LandingPage } from "../landingPage/LandingPage";
import { InfoPage } from "../infoPage/InfoPage";
import { Intro } from "../intro/Intro";
import { Menu } from "./Menu";
import { Credits } from "../credits/Credits";
// Project
import axios from "axios";
import { parseSync, stringifySync } from "subtitle";

export const Home = (props: any) => {
	useEffect(() => {
		if (document.location.pathname === "/aoe") {
			displayInfoPage("aoe");
		} else if (document.location.pathname === "/eats") {
			displayInfoPage("eats");
		}
	}, []);

	const [videoName, setVideoName] = useState("ELYSEE_3K");
	const _videoName = useRef(videoName);

	useEffect(() => {
		_videoName.current = videoName;
	}, [videoName]);

	const [maxSize, setMaxSize] = useState(2880);

	// Sound
	const [sound, setSound] = useState(true);

	const toggleSound = () => {
		console.log("Toggle Sound Trigger");
		let videoElement = document.getElementById("video");

		if (videoElement) {
			(videoElement as HTMLVideoElement).muted = !(videoElement as HTMLVideoElement).muted;
			setSound(!sound);
		}
	};

	// Subtitles
	const [subtitles, setSubtitles] = useState(false);

	const toggleSubtitles = () => {
		console.log("Toggle Subtitles Trigger");
		let srtElem = document.getElementById("srt");

		if (srtElem) {
			srtElem.style.opacity = subtitles ? "0" : "1";
		}
		setSubtitles(!subtitles);
	};

	// Pages display
	const [introPage, displayIntroPage] = useState(true);
	const [infoPage, displayInfoPage] = useState<string | null>(null);
	const [credits, setCredits] = useState(false);
	const [landingPage, setLandingPage] = useState<string | null>(null);

	// Loader
	const [buffered, setBuffered] = useState(0);
	const [pausedSince, setPausedSince] = useState(50);
	const [loader, setLoader] = useState(false);

	//Srt
	//const [srtData, setSrtData] = useState<NodeList | undefined>(undefined);

	useEffect(() => {
		let interval: NodeJS.Timer;
		//console.log("load srt")

		axios.get("/assets/videos/" + (_videoName.current.includes("EATS") ? "eats" : "aoe") + ".srt").then((res) => {
			//console.log(res.data);

			let nodes = parseSync(res.data);

			//console.log(nodes)

			interval = setInterval(function () {
				var videoElement = document.querySelector("video");

				if (videoElement) {
					//console.log(videoElement.currentTime)

					let found = null;
					let currentTiming = videoElement.currentTime * 1000;

					for (let n of nodes) {
						if (n.type === "cue") {
							if (n.data.start < currentTiming && currentTiming < n.data.end) {
								found = n.data.text;
							}
						}
					}

					var srtElement = document.getElementById("srt");

					if (found) {
						//console.log(found);
						if (srtElement && subtitles === true) {
							srtElement.style.opacity = "1";
							srtElement.innerHTML = found;
						}
					} else {
						if (srtElement) {
							srtElement.style.opacity = "0";
							srtElement.innerHTML = "";
						}
					}
				}
			}, 100);
		});

		return () => {
			clearInterval(interval);
		};
	}, [subtitles]);

	useEffect(() => {}, [buffered]);

	useEffect(() => {
		let inter = setInterval(() => {
			if (pausedSince > 0) {
				if (Date.now() - pausedSince > 1000) {
					setLoader(true);
					return;
				}
			}
			setLoader(false);
		}, 1000);

		return () => clearInterval(inter);
	});

	useEffect(() => {}, [pausedSince]);

	const displayCredits = (value: boolean) => {
		if (value) {
			let videoElement = document.getElementById("video");
			if (videoElement) {
				(videoElement as HTMLVideoElement).pause();

				let homeElement = document.getElementById("home");

				if (homeElement) {
					homeElement.style.pointerEvents = "all";
				}
			}

			let viewportElement = document.getElementById("viewport");
			if (viewportElement) {
				viewportElement.style.width = "0%";
				viewportElement.style.height = "0%";
				viewportElement.style.opacity = "0";
			}

			setCredits(true);
		} else {
			let videoElement = document.getElementById("video");
			if (videoElement && !introPage && !landingPage) {
				(videoElement as HTMLVideoElement).play();

				let homeElement = document.getElementById("home");

				if (homeElement) {
					homeElement.style.pointerEvents = "none";
				}
			}

			let viewportElement = document.getElementById("viewport");
			if (viewportElement) {
				viewportElement.style.width = "100%";
				viewportElement.style.height = "100%";
				viewportElement.style.opacity = "1";
			}

			setCredits(false);
		}
	};

	const newName = (name: string = "ELYSEE") => {
		//if (maxSize < 2880) {
		name += "_2K";
		/*} else {
			name += '_3K';
		}*/
		// console.log("VideoName =", name);
		setVideoName(name);
	};

	useEffect(() => {
		newName();
	}, []);

	const introDisapear = (move: string) => {
		if (move === "aoe") {
			newName("ELYSEE");
		} else {
			newName("ELYSEE_EATS");
		}
		let videoElement = document.getElementById("video");
		if (videoElement) {
			setTimeout(() => {
				setPausedSince(Date.now());

				if (videoElement) {
					let test = (videoElement as HTMLVideoElement).src;
					// console.log(test);
				}

				(videoElement as HTMLVideoElement).play();

				if (subtitles) {
					let srtElem = document.getElementById("srt");

					if (srtElem) {
						srtElem.style.opacity = "1";
					}
				}
			}, 900);

			let homeElement = document.getElementById("home");

			if (homeElement) {
				homeElement.style.pointerEvents = "none";
			}

			setTimeout(() => {
				let elem = document.getElementById("viewport");

				if (elem) {
					elem.style.opacity = "1";
				}
			}, 500);
		} else {
			alert("video not ok");
		}

		setTimeout(() => {
			displayIntroPage(false);
			displayInfoPage(null);
		}, 500);
	};

	return (
		<div style={{ width: "100%", height: "100%", backgroundColor: "black" }}>
			{/* THREE Instance */}
			<div className='viewport'>{<Sphere360 setMaxSize={setMaxSize} />}</div>
			{!introPage && !infoPage && !landingPage && !credits && (
				<Menu
					sound={sound}
					toggleSound={toggleSound}
					displayCredits={displayCredits}
					subtitles={subtitles}
					toggleSubtitles={toggleSubtitles}
				/>
			)}
			<div id='home' className='home'>
				{/* Video hide in background */}
				<video
					id='video'
					playsInline
					preload='metadata'
					width='0'
					height='0'
					src={"./assets/videos/" + videoName + ".mp4"}
					style={{
						position: "absolute",
						opacity: 0,
						margin: 0,
						padding: 0,
					}}
					onEnded={(e) => {
						let viewportElement = document.getElementById("viewport");
						if (viewportElement) {
							viewportElement.style.width = "0%";
							viewportElement.style.height = "0%";
							viewportElement.style.opacity = "0";
						}

						if (!videoName.includes("EATS")) {
							setLandingPage("aoe");
						} else {
							setLandingPage("eats");
						}
					}}
					onCanPlay={(e) => {
						if (!introPage && !landingPage) {
							e.currentTarget.play();
							let homeElement = document.getElementById("home");

							if (homeElement) {
								homeElement.style.pointerEvents = "none";
							}
						}
					}}
					onWaiting={(e) => {
						setPausedSince(Date.now());

						let homeElement = document.getElementById("home");

						if (homeElement) {
							homeElement.style.pointerEvents = "all";
						}
					}}
					onPlaying={(e) => {
						setPausedSince(-1);

						let homeElement = document.getElementById("home");

						if (homeElement) {
							homeElement.style.pointerEvents = "none";
						}
					}}
					onProgress={(e) => {
						let video = e.currentTarget;

						if (video) {
							if (video.buffered.length > 0) {
								var bufferedEnd = video.buffered.end(video.buffered.length - 1);
								var duration = video.duration;
								if (duration > 0) {
									let bufferedAmount = document.getElementById("buffered-amount");
									if (bufferedAmount) {
										bufferedAmount.style.width = (bufferedEnd / duration) * 100 + "%";
										setBuffered(Math.trunc((bufferedEnd / duration) * 100));
									}
								}
							}
						}
					}}
					onTimeUpdate={(e) => {
						let video = e.currentTarget;

						if (video) {
							var duration = video.duration;
							if (duration > 0) {
								let progressAmount = document.getElementById("progress-amount");
								if (progressAmount) {
									progressAmount.style.width = (video.currentTime / duration) * 100 + "%";
								}
								let cursor = document.getElementById("cursor");
								if (cursor) {
									cursor.style.left = "calc(" + (video.currentTime / duration) * 100 + "% - 8px)";
								}
							}
						}
					}}
				>
					{/* <source src={'./assets/videos/' + videoName + '.webm'} type='video/webm'></source> */}
				</video>

				{/* Intro Page */}
				{!credits && introPage && (
					<Intro
						sound={sound}
						toggleSound={toggleSound}
						displayCredits={displayCredits}
						subtitles={subtitles}
						toggleSubtitles={toggleSubtitles}
						next={introDisapear}
						infos={displayInfoPage}
					/>
				)}

				{/* Landing Page */}
				{landingPage && (
					<LandingPage
						displayInfoPage={displayInfoPage}
						setLandingPage={setLandingPage}
						landingPage={landingPage}
						next={introDisapear}
					/>
				)}

				{/* Landing Page */}
				{infoPage && <InfoPage infoPage={infoPage} hide={() => displayInfoPage(null)} next={introDisapear} />}

				{/* Credits */}
				{credits && <Credits displayCredits={displayCredits} />}
			</div>
			{!landingPage && !introPage && (
				<div className='videoInterface' style={{ pointerEvents: "none" }}>
					<div className='srt'>
						<div
							id='srt'
							style={{
								opacity: "0",
							}}
						></div>
					</div>
					<div
						id='progressbar'
						className='progressbar'
						onClick={(e) => {
							let progressbar = document.getElementById("progressbar");

							if (progressbar) {
								var offset = progressbar.getClientRects()[0];
								let dist = e.clientX - offset.left - 10;
								let percentage = (dist * 100) / offset.width;

								let videoElement = document.getElementById("video");
								if (videoElement) {
									(videoElement as HTMLVideoElement).currentTime =
										(percentage / 100) * (videoElement as HTMLVideoElement).duration;
								}
							}
						}}
						onTouchEnd={(e) => {
							let progressbar = document.getElementById("progressbar");

							if (progressbar) {
								var offset = progressbar.getClientRects()[0];
								let dist = e.changedTouches[0].clientX - offset.left - 10;
								let percentage = (dist * 100) / offset.width;

								let videoElement = document.getElementById("video");
								if (videoElement) {
									(videoElement as HTMLVideoElement).currentTime =
										(percentage / 100) * (videoElement as HTMLVideoElement).duration;
								}
							}
						}}
					>
						<div
							className='buffered'
							style={{
								height: "8px",
								position: "absolute",
								background: "#555",
								width: "calc(100% - 20px)",
								left: "10px",
								bottom: "10px",
								opacity: "0.4",
							}}
						>
							<span
								id='buffered-amount'
								style={{
									display: "block",
									height: "100%",
									backgroundColor: "#888",
									width: "0",
								}}
							></span>
						</div>
						<div
							className='progress'
							style={{
								height: "8px",
								position: "absolute",
								width: "calc(100% - 20px)",
								left: "10px",
								bottom: "10px",
								opacity: "0.3",
							}}
						>
							<span
								id='progress-amount'
								style={{
									display: "block",
									height: "100%",
									backgroundColor: "#595",
									width: "0",
								}}
							></span>
						</div>
						<div
							id='cursor'
							className='cursor'
							style={{
								height: "12px",
								position: "absolute",
								width: "12px",
								left: "8px",
								bottom: "6px",
								opacity: "1",
								backgroundColor: "#6f8274",
								borderRadius: "6px",
								border: "2px solid white",
							}}
						></div>
					</div>
				</div>
			)}

			{loader && !introPage && !landingPage && <Loader buffered={buffered} />}
		</div>
	);
};
