import React from "react";
import { trads } from "../../datas/trads";

export const italiquize = (value: string, tradOrigin: string, i: number) => {
	if (!value || value === "") {
		return <React.Fragment></React.Fragment>;
	}

	let tradParsed = value.split("%i");

	return (
		<React.Fragment>
			{tradParsed.map((t: any, index: number) => {
				if (index % 2 === 0) {
					// Just string
					return <span key={'t-'+tradOrigin+i+'-'+index}>{t}</span>;
				} else {
					// Link
					return (
						<span key={'t-'+tradOrigin+i+'-'+index} style={{ fontStyle: 'italic' }}>
							{t}
						</span>
					);
				}
			})}
		</React.Fragment>
	);
};

export const GetTrad = (props: any) => {
	let language = "fr";

	let tradsDatas = trads.find((t: any) => t.id === language);
	let trad = tradsDatas ? (tradsDatas as any)[props.value] : undefined;
	let tradParsed = trad.split("%l");

	if (!props.value || props.value === "" || !trad) {
		return <React.Fragment></React.Fragment>;
	}

	return (
		<React.Fragment>
			{tradParsed.map((t: any, index: number) => {
				if (index % 2 === 0) {
					// Just string
					return <span key={'t-'+props.value+'-'+index}>{italiquize(t, props.value, index)}</span>;
				} else {
					// Link
					return (
						<a key={'t-'+props.value+'-'+index} href={"https://www.timescope.com/"} className={"link"} style={{ fontWeight: "700" }}>
							{italiquize(t, props.value, index)}
						</a>
					);
				}
			})}
		</React.Fragment>
	);
};
