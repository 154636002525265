export const trads = [
	{
		id: "fr",

		// AOE
		aoeTitle: "Aux Origines de l'Élysée",
		aoeFaubourg: "Un palais construit dans un faubourg...",
		aoeStartBuilding:
			"En 1722, le Comte d’Évreux lance la construction de son hôtel particulier dans le faubourg Saint-Honoré, aux abords de Paris. Sur un terrain humide, et marécageux, l’architecte Armand Claude Mollet érige un palais typique du début du XVIIIe siècle. La situation du Palais ne doit rien au hasard. Il est proche du Palais des Tuileries, et son jardin donne... sur le Grand Cours, connu aujourd’hui sous le nom de Champs-Élysée !",
		aoeOriginsVR: "Remontez le temps en réalité virtuelle et découvrez les origines du Palais de l'Élysée !",
		aoeTimescopeExp:
			"Une expérience de voyage dans le temps réalisée par %lTimescope%l, en partenariat avec la Présidence de la République.",

		// EATS
		eatsTitle: "L'Élysée à travers les siècles",
		eatsPeriod: "De 1722 à nos jours...",
		eatsMoreThan300:
			"Le Palais de l’Élysée, c’est plus de 300 ans d’histoire, une succession d'évolutions architecturales… et de propriétaires illustres ! Certains y laissent une empreinte esthétique, d’autres adaptent le palais à de nouveaux usages.",
		eatsFiguresList:
			"Le Comte d’Évreux, la Duchesse de Bourbon, Napoléon III, Sadi Carnot, le Général de Gaulle... De 1722 à nos jours, vivez en réalité virtuelle les transformations extraordinaires du Palais de l’Élysée.",
		eatsTimescopeExp:
			"Une expérience de voyage dans le temps réalisée par %lTimescope%l, en partenariat avec la Présidence de la République.",

		// Endpage
		travelProposedFor:
			"Ce voyage dans le temps vous a été proposé à l'occasion des Journées Européennes du Patrimoine.",
		travelProposedBy: "Une expérience en réalité virtuelle réalisée par %lTimescope%l",
		otherTravelAOE: "Découvrez un autre voyage dans le temps pour revenir %iAux Origines de l'Élysée%i !",
		otherTravelEATS: "Découvert un autre voyage dans le temps pour découvrir %iL'Élysée à Travers les Siècles%i !",
	},
];
