// Styles
import './credits.css';
// Icons
import close from '../../style/assets/icons/close.png';
import React from 'react';

export const Credits = (props: any) => {
	const goBack = () => {
		props.displayCredits(false);
	};

	return (
		<React.Fragment>
			<div
				className='clickable'
				style={{
					position: 'absolute',
					top: '20px',
					right: '20px',
					width: '25px',
					height: '25px',
					zIndex: 20000,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',

					backgroundImage: `url(${close})`,
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
				}}
				onClick={() => {
					goBack();
				}}
			></div>
			<div
				className='credits'
				id='credits'
				style={{
					zIndex: 8000,
					position: 'absolute',
					top: 0,
				}}
			>
				<h1>Crédits</h1>
				<p>
					Cette expérience de « voyage dans le temps » est le fruit d’un partenariat entre la Présidence de la République et la société Timescope,
					pionnière de l’innovation culturelle au service de la valorisation du patrimoine.
				</p>
			</div>
		</React.Fragment>
	);
};
