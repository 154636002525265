// React
import React, { useEffect, useState } from "react";
// CSS
import "./infoPage.css";
// Datas
import { experiences } from "../../datas/experiences";
// Assets
import timescopeLogo from "../../style/assets/intro/timescopeLogo.png";
import prLogo from "../../style/assets/intro/prLogo.png";
import jepLogo from "../../style/assets/intro/jepLogo.png";
import { GetTrad } from "../Partials/GetTrad";

export const InfoPage = (props: any) => {
	const [experience, setExperience] = useState<any>();

	useEffect(() => {
		setExperience(experiences.find((e: any) => e.id === props.infoPage));
	}, [props.infoPage]);

	return (
		<React.Fragment>
			{experience && (
				<div className='infoPage'>
					<div className='infoPage-overflowed'>
						<div className='infoPage-band' onClick={() => props.hide()}>
							<div className='infoPage-band-cross'></div>
						</div>
						<div
							className='infoPage-title'
							style={{ backgroundImage: `url(${experience.title.img})` }}
						></div>
						<div
							className='infoPage-affiche'
							style={{ backgroundImage: `url(${experience?.affiche.normal})` }}
						></div>
						<div className='infoPage-content'>
							{experience.content.map((c: any, index: number) => {
								switch (c.type) {
									case "title":
										return (
											<div key={'expcontent-'+index} className='infoPage-content-title'>
												<GetTrad value={c.value} />
											</div>
										);
									case "text":
										return (
											<div key={'expcontent-'+index} className='infoPage-content-text'>
												<GetTrad value={c.value} />
											</div>
										);
								}
							})}
						</div>
						<div className='infoPage-bloc'>
							<div className='intro-bloc-header'>
								<GetTrad value={experience.movie.title} />
							</div>
							<div
								className='intro-bloc-videoTrailer btnStart'
								onClick={() => {
									props.next(experience.id);
								}}
								style={{ backgroundImage: `url(${experience.movie.vignette})` }}
							>
								<div className='intro-bloc-videoTrailer-playIcon'></div>
								{experience.movie.new && (
									<div className='intro-bloc-videoTrailer-newIcon'>
										<div className='intro-bloc-videoTrailer-newIconText'>Nouveau</div>
									</div>
								)}
							</div>
							<div className='intro-bloc-infos'>
								<div className='intro-bloc-infos-more'></div>
								<div className='intro-bloc-infos-timer'>{experience.movie.duration}</div>
							</div>
						</div>
						<div
							className='infoPage-share'
							onClick={() => {
								navigator.share({
									url: "/" + experience.id,
									text: experience.share.header,
									title: experience.share.header
								});
							}}
						>
							<div className='infoPage-share-icon'></div>
							<div className='infoPage-share-text'>partager</div>
						</div>
						<div className='intro-logos'>
							<div
								className='intro-logos-logo'
								style={{ backgroundImage: `url(${timescopeLogo})` }}
							></div>
							<div className='intro-logos-logo' style={{ backgroundImage: `url(${prLogo})` }}></div>
							<div className='intro-logos-logo' style={{ backgroundImage: `url(${jepLogo})` }}></div>
						</div>
					</div>
				</div>
			)}

			{/* <div id='intro-fade-bottom' className='intro-fade-bottom'></div> */}
		</React.Fragment>
	);
};
