// Aoe
import aoeTitleImg from "../style/assets/infoPage/aoe/title.png";
import aoeAfficheNormalImg from "../style/assets/infoPage/aoe/affiche.jpg";
import aoeAfficheBlurImg from "../style/assets/infoPage/aoe/afficheBlur.jpg";
import aoeVignette from "../style/assets/intro/vignetteAOE.jpg";

// Eats
import eatsTitleImg from "../style/assets/infoPage/eats/title.png";
import eatsAfficheNormalImg from "../style/assets/infoPage/eats/affiche.jpg";
import eatsAfficheBlurImg from "../style/assets/infoPage/eats/afficheBlur.jpg";
import eatsVignette from "../style/assets/intro/vignetteEATS.jpg";

export const experiences = [
	{
		id: "aoe",
		title: {
			value: "aoeTitle",
			img: aoeTitleImg,
		},
		affiche: {
			normal: aoeAfficheNormalImg,
			blur: aoeAfficheBlurImg,
		},
		content: [
			{
				type: "title",
				value: "aoeFaubourg",
			},
			{
				type: "text",
				value: "aoeStartBuilding",
			},
			{
				type: "text",
				value: "aoeOriginsVR",
			},
			{
				type: "text",
				value: "aoeTimescopeExp",
			},
		],
		endPage: [
			{
				type: "text",
				value: "travelProposedFor",
			},
			{
				type: "share",
			},
			{
				type: "text",
				value: "travelProposedBy",
			},
			{
				type: 'text',
				value: 'otherTravelEATS'
			}
		],
		movie: {
			title: "aoeTitle",
			vignette: aoeVignette,
			new: false,
			duration: "2:52",
		},
		share: {
			header: "Aux Origines de l'Élysée, 1722.",
		},
	},
	{
		id: "eats",
		title: {
			value: "eatsTitle",
			img: eatsTitleImg,
		},
		affiche: {
			normal: eatsAfficheNormalImg,
			blur: eatsAfficheBlurImg
		},
		content: [
			{
				type: "title",
				value: "eatsPeriod",
			},
			{
				type: "text",
				value: "eatsMoreThan300",
			},
			{
				type: "text",
				value: "eatsFiguresList",
			},
			{
				type: "text",
				value: "eatsTimescopeExp",
			},
		],
		endPage: [
			{
				type: "text",
				value: "travelProposedFor",
			},
			{
				type: "share",
			},
			{
				type: "text",
				value: "travelProposedBy",
			},
			{
				type: 'text',
				value: 'otherTravelAOE'
			}
		],
		movie: {
			title: "eatsTitle",
			vignette: eatsVignette,
			new: true,
			duration: "4:38",
		},
		share: {
			header: "L'Élysée à travers les siècles.",
		},
	},
];
